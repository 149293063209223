<template>
  <div class="box">
    <Header> 邀请设置 </Header>

    <div id="mycanvas">
      <div class="my">我的邀请码</div>
      <div class="vip">所属会员服务机构：{{ UserAgentName }}</div>
      <!--<img class="haiBaoErWeiMa" src="../../assets/images/1.jpg" alt="" />-->
      <div class="bgCode">
        <vue-qr class="QrcodeBg" :text="url" :size="212" :margin="3"> </vue-qr>
      </div>
      <div class="posterText">{{ proportionNum }}</div>
    </div>

    <van-collapse v-model="activeNames">
      <van-collapse-item title="我的邀请" name="2">
        <div class="ni_name">
          <div>昵称：</div>
          <div class="time">时间：</div>
        </div>
        <div v-for="item in getInvitationList" :key="item.index">
          <div>
            <div class="person">
              <div class="person_name" v-if="item.realName == ''">
                {{ item.name }}
              </div>
              <div class="person_name" v-else-if="item.realName != ''">
                {{ item.realName }}
              </div>
              <div class="tiem_at">{{ item.created_at }}</div>
            </div>
          </div>
        </div>
        <van-pagination
          v-model="currentPage"
          :page-count="pagecount"
          @change="changeCurrentPage"
          mode="simple"
        />
      </van-collapse-item>
      <div class="num" v-text="num"></div>
    </van-collapse>
  </div>
</template>

<script>
import VueQr from 'vue-qr';
import html2canvas from 'html2canvas';

export default {
  name: 'EvaluationList',
  components: { VueQr },
  data() {
    let proportionNum = localStorage.getItem('proportionNum');
    return {
      //logo: "https://img1.baidu.com/it/u=1966616150,2146512490&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1656781200&t=6bb14b7a7af4f37d7c6d4143e0150c28",
      logo: '',
      url: `http://v.hxnft.shop/#/login?code=${proportionNum}`,
      proportionNum: '',
      saveUrl: '',
      currentPage: 1, //分页-----第一页
      itemspage: 5, // 分页----每页多少条
      total: '', // 总记录数
      pagecount: '',
      UserAgentName: '',
      // 推荐人
      activeNames: ['1'],
      getInvitationList: [],
      name: '',
      tel: '',
    };
  },
  created() {
    this.proportionNum = localStorage.getItem('proportionNum');

    //二维码测试环境
    // this.url = `http://ibox.azging.com/#/register?id=${this.proportionNum}`;
    // //二维码生产环境
    // this.url = `http://c.cdsaoshu.com//#/register?id=${this.proportionNum}`;
  }, // 生命周期-创建完成
  mounted() {
    setTimeout(() => {
      this.saveImg();
    }, 1000);
    let token = {
      token: localStorage.getItem('token'),
    };
    this.$api.anyUserinfo(token).then((res) => {
      if (res.code == 0) {
        this.UserAgentName = res.data.UserAgentName;
      }
    });

    this.getList();
  }, // 生命周期-创建之前
  methods: {
    getList() {
      //   邀请人
      let params = {
        pageindex: this.currentPage,
        pagesize: this.itemspage,
        token: localStorage.getItem('token'),
      };
      this.$api.getInvitationList(params).then((res) => {
        if (res.code == 0) {
          this.getInvitationList = res.data;
          this.num = res.count + '  ' + '位';
          this.total = res.count;
          this.pagecount = Math.ceil(this.total / 5);
        }
      });
    },
    // 分页变化事件
    changeCurrentPage() {
      this.getList();
    },
    saveImg() {
      // 先获取你要转换为img的dom节点
      var node = document.getElementById('mycanvas'); // 传入的id名称
      var width = node.offsetWidth; // dom宽
      var height = node.offsetHeight; // dom高
      var scale = 2; // 放大倍数 这个相当于清晰度 调大一点更清晰一点
      html2canvas(node, {
        width: width,
        heigth: height,
        backgroundColor: '#ffffff', // 背景颜色 为null是透明
        dpi: window.devicePixelRatio * 2, // 按屏幕像素比增加像素
        scale: scale,
        X: 0,
        Y: 0,
        scrollX: -3, // 如果左边多个白边 设置该偏移-3 或者更多
        scrollY: 0,
        useCORS: true, // 是否使用CORS从服务器加载图像 !!!
        allowTaint: true, // 是否允许跨域图像污染画布  !!!
      }).then((canvas) => {
        this.saveUrl = canvas.toDataURL('image/png');
        // let aLink = document.createElement('a')
        // this.blob = this.base64ToBlob(saveUrl)
        // let evt = document.createEvent('HTMLEvents')
        // evt.initEvent('click', true, true)
        // aLink.download = '二维码.jpg'
        // aLink.href = URL.createObjectURL(blob)
        // aLink.click()
        //	        var Url = canvas.toDataURL(); // 这里上面不设值cors会报错
        //	        var a = document.createElement('a'); // 创建一个a标签 用来下载
        //	        a.download = '海报二维码'; // 设置下载的图片名称
        //	        var event = new MouseEvent('click'); // 增加一个点击事件
        //	        a.href = Url; // 此处的url为base64格式的图片资源
        //	        a.dispatchEvent(event); // 触发a的单击事件 即可完成下载
      });
    },
    base64ToBlob(code) {
      let parts = code.split(';base64,');
      let contentType = parts[0].split(':')[1];
      let raw = window.atob(parts[1]);
      let rawLength = raw.length;
      let uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    },
  },
};
</script>

<style scoped lang="less">
.ni_name {
  display: flex;
  justify-content: space-between;
}
.person_name {
  color: red;
  font-size: 12px;
  width: 80px;
  overflow: hidden; /*超出的部分隐藏起来。*/
  white-space: nowrap; /*不显示的地方用省略号...代替*/
  text-overflow: ellipsis;
}
.time {
  margin-right: 75px;
}
.tiem_at {
  width: 125px;
  overflow: hidden; /*超出的部分隐藏起来。*/
  white-space: nowrap; /*不显示的地方用省略号...代替*/
  text-overflow: ellipsis;
}
.person {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
}
.num {
  font-size: 13px;
  color: #999999;
  position: absolute;
  top: 13px;
  right: 60px;
}
/deep/.van-cell {
  width: 341px;
  margin-top: 13px;
  margin-left: 17px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
/deep/.van-collapse-item__wrapper {
  border-bottom-left-radius: 5px;
  width: 341px;
  margin-left: 17px;
  border-bottom-right-radius: 5px;
}
.box {
  background-color: #f8f8f8;
  height: 150vh;
}
#mycanvas {
  background-color: #ffffff;
  width: 341px;
  height: 320px;
  margin-left: 17px;
  margin-top: 20px;
  border-radius: 5px;
  position: relative;
  .my {
    font-size: 15px;
    text-align: center;
    padding: 10px 0;
  }
  .posterText {
    font-size: 14px;
    position: relative;
    // top: 25px;
    text-align: center;
  }
}
.bgCode {
  background-size: 100%;
}
.QrcodeBg {
  position: relative;
  // top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.vip {
  font-size: 14px;
  margin-left: 20px;
  padding-bottom: 10px;
}
</style>
